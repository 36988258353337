<template>
  <div class="admin">
    <div class="mianbao">
      <div class="breadcrumb">
				<span style="color: #fff;">
					普查动态 >
				</span>
        <span style="color:#016ae0 ;">
					普查二维码管理
				</span>
      </div>
    </div>
    <div class="search">
      枯死木编号：
      <a-input v-model="form.treeId" style="color:#fff;width: 10%;margin-right:40px;background-color: transparent;" placeholder="请输入编号"></a-input>
      <a-button type="primary" style="margin-right:1rem" @click="handleList">查询</a-button>
      <a-button type="primary" style="margin-right:1rem" @click="generateClick">生成二维码</a-button>
    </div>
    <div class="content">
      <div class="erweima">
        <a-spin class="spin" :spinning="spinning" />
        <div class="list">
          <div class="item" :style="{'margin-right':(index+1)%4==0?'0':'2%'}" v-for="(item, index) in list" :key="index">
            <div class="item_center">
              <div class="number">
                枯死木编号：{{item.treeId}}
              </div>
              <div class="img">
                <img :src="api + '/' + item.qrSrc" >
              </div>
            </div>
          </div>
        </div>
        <div class="fenye">
          <a-pagination v-model="form.pageNum" :total="total" @change="onShowSizeChange" show-less-items />
        </div>
      </div>
    </div>
    <Cebian :show="show" @handleShow="handleShow" @handleProject="handleProject" />
    <!-- 生成二维码 -->
    <a-config-provider :locale="locale">
      <a-modal class="a_quantity" v-model="visible" :maskClosable="false" title="生成/打印二维码" @ok="handleOks" @cancel="handleNOs" :dialogStyle="dialogStyle">
        <div>
          <div>生成数量： <a-input-number v-model="value" :min="1" :max="300" /> 张</div>
          <div style="margin:20px 0">生成代码：
            <a-select style="width:80px" @change="handleChanges" v-model="prefix">
              <a-select-option v-for="(item, index) in options" :key="index" :value="item.value">
                {{item.value}}
              </a-select-option>
            </a-select>
          </div>
          <div :id="'printDiv' + index" style="text-align:center;margin-bottom:1rem;" ref="printMe" v-for="(item, index) in codes" :key="index">
            <!-- <img :src="'http://pwn.xcforest.com/plague/api/' + item.qrSrc" :alt="index+1"> -->
            <img :src="'data:image/jpeg;base64,' + item.qrSrc" :alt="index+1">
            <div class="three">
              <div>枯死木编号：{{item.treeId}}</div>
              <div class="three-pathogenesis"><div class="three-frame"></div> 高度疑似松材线虫病；</div>
              <div class="three-pathogenesis"><div class="three-frame"></div> 树木因松枝枯病后枯枝、死亡；</div>
              <div class="three-pathogenesis"><div class="three-frame"></div> 其他原因导致松树异常枯萎或死亡；</div>
            </div>
          </div>
          <a-button @click="handlePrint">打印</a-button>
        </div>
      </a-modal>
    </a-config-provider>
  </div>
</template>

<script>
import QRCode from 'qrcodejs2';
import Cebian from '../../components/cebian.vue'
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN';
// 上传图片转为Base64
function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}
export default {
  inject: ['reload'],
  components:{
    Cebian,
    QRCode
  },
  data(){
    return{
      show:false,
      list:[],
      form:{
        pageNum:1,
        pageSize:8,
        projectIdList: sessionStorage.getItem('projectId'), //根据项目id获取数据
      },
      total:null,
      spinning:false,
      // -----------------------------
      projectIdList: sessionStorage.getItem('projectId'), //根据项目id获取数据
      deletes: [], //删除二维码数组
      locale: zhCN, // 弹框中文
      // 上传图片
      loading: false,
      imageUrl: '',
      visible: false, //生成二维码弹框显示/隐藏
      dialogStyle: { // 二维码弹框显示位置
        top: '15rem',
        width: '10rem'
      },
      value: 1,
      titles: '', // 生成/打印
      codes: [], // 二维码数组
      prefix: 'A',
      options: [{
        value:'A'
      },{
        value:'B'
      },{
        value:'C'
      },{
        value:'D'
      },{
        value:'E'
      },{
        value:'F'
      },{
        value:'G'
      },{
        value:'H'
      },{
        value:'I'
      },{
        value:'J'
      },{
        value:'K'
      },{
        value:'L'
      },{
        value:'M'
      },{
        value:'N'
      },{
        value:'O'
      },{
        value:'P'
      },{
        value:'Q'
      },{
        value:'R'
      },{
        value:'S'
      },{
        value:'T'
      },{
        value:'U'
      },{
        value:'V'
      },{
        value:'W'
      },{
        value:'X'
      },{
        value:'Y'
      },{
        value:'Z'
      }]
    }
  },
  mounted(){
    this.handleList()
  },
  methods:{
    // 生成二维码
    generateClick() {
      this.titles = '生成二维码'
      this.visible = true;
    },
    // 选择代码
    handleChanges(e) {
      this.prefix = e
    },
    // 提交生成
    async handleOks(e) {
      this.$message.success('生成中,请稍后')
      let count = this.value
      let prefix = this.prefix
      const res = await this.$axios.get(this.api + '/qrCode/create', {
        params: {
          count,
          prefix
        }
      })
      if(res.code === 200) {
        setTimeout(() => {
          this.codes = res.data
          this.$message.success('已生成,请直接打印')
        }, 1000)
      }
    },
    // 打印
    handlePrint() {
      var newWin = window.open(""); //新打开一个空窗口
      for (var i = 0; i < this.codes.length; i++) {
        var imageToPrint = document.getElementById("printDiv" + i); //获取需要打印的内容
        newWin.document.write(imageToPrint.outerHTML); //将需要打印的内容添加进新的窗口
      }
      const styleSheet = `<style>li{list-style:none}</style>`;
      newWin.document.head.innerHTML = styleSheet; //给打印的内容加上样式
      newWin.document.close(); //在IE浏览器中使用必须添加这一句
      newWin.focus(); //在IE浏览器中使用必须添加这一句
      setTimeout(function() {
        newWin.print(); //打印
        newWin.close(); //关闭窗口
      }, 100);
    },
    // 关闭时触发
    handleNOs() {
      this.reload()
    },
    // 项目查询
    handleProject(e){
      this.form.projectIdList=e
      this.handleList()
    },
    handleShow(show){
      // console.log(show)
      this.show=show
    },
    // 二维码列表
    handleList(){
      this.spinning=true
      this.$axios(this.api+'/qrCode/list',{params:this.form}).then(res=>{
        this.total=res.data.total
        this.list=res.data.list
        this.spinning=false
      })
    },
    // 分页
    onShowSizeChange(pageNum, pageSize) {
      this.form.pageNum = pageNum;
      this.handleList()
    },
    // 二维码选择/修改
    selectChange(e) {
      this.deletes.push(e) //选择的二维码id
    },
    // 点击修改
    modificationClick() {
      if(this.deletes.length !== 1) {
        return this.$message.warning('一次只能修改一个哦！')
      }
      this.visible = true;
    },
    // 重置
    resetClick() {
      this.reload()
      this.deletes = []
    }
  }
}
</script>

<style lang="less" scoped>
div /deep/ .ant-pagination-item-ellipsis{
  color: #fff !important;
}
.admin{
  width:100%;
  height:100%;
  background-color: #f5f5f5;
  padding:10px 40px 20px 40px;
  background: url(../../assets/bg01.jpg) no-repeat;
  background-size: 100% 100%;
}
.mianbao{
  height:40px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* background-color: yellow; */
}
.search{
  width: 100%;
  height: 80px;
  border:1px solid #016ae0 ;
  border-radius: 8px;
  color: #FFFFFF;
  /* background-color: #fff; */
  margin-top: 10px;
  display: flex;
  align-items: center;
  padding: 0 20px;
}
.content{
  width: 100%;
  height: calc(100% - 140px);
  /* background-color: #fff; */
  border:1px solid #016ae0 ;
  border-radius: 8px;
  margin-top: 20px;
  padding:20px ;
  color: #FFFFFF;
}
.erweima{
  width: 100%;
  margin-top: 8px;
  height: calc(100% - 40px);
  position: relative;
}
.spin{
  position: absolute;
  left: 50%;
  top: 40%;
}
.list{
  display: flex;
  flex-wrap: wrap;
  height:calc(100% - 40px);
}
.item{
  width: 23.5%;
  height:calc(50% - 21px);
  margin-bottom: 10px;
}
.fenye{
  height: 32px;
  margin-top: 8px;
  text-align: right;
}
/* ------------------------------------------------------ */
/* 单选 */
.ant-radio-wrapper /deep/ .ant-radio-inner {
  border-color: #005ea1;
  background-color: transparent;
}
.ant-radio-wrapper /deep/ .ant-radio-inner::after {
  background-color: #005ea1;
}
/* 修改 */
div /deep/ .ant-modal-body {
  /* display: flex; */
  justify-content: center;
  align-items: center;
}
div /deep/ .ant-modal {
  width: 20% !important;
}
@media print {
  #printStyle .item{
    margin: 10px;
  }
  #printStyle h2{
    font-size: 30px;
  }
}
/* ------------------------------------------------------ */
.item_center{
  border: 1px solid #005ea1;
  width: 100%;
  margin-top: 15px;
  height:calc(100% - 30px);
}
.number{
  height: 60px;
  display: flex;
  padding:0 20px;
  align-items: center;
  font-size: 18px;
}
.img{
  border-top:1px solid #005ea1;
  height: calc(100% - 60px);
  display: flex;
  align-items: center;
  justify-content: center;
}
.img img{
  height: 80%;
  width: auto;
}
.three {
  width: 97%;
  margin: 0 auto;
  text-align: left;
  font-size: 18px;
  font-weight: 600;
  font-family: cursive;
  color: #149153;
  .three-pathogenesis {
    display: flex;
    align-items: center;
    margin-left: 3px;
    .three-frame {
      width: 13px;
      height: 13px;
      border: 2px solid #149153;
      margin-right: 8px;
    }
  }
}
</style>
